import React from 'react';

import Container from '../../container/container';
import styles from './devops-section.module.scss';

import Hosting from '../../../images/icons/hosting.svg';
import Backup from '../../../images/icons/backup.svg';
import Support from '../../../images/icons/support.svg';

const DevopsSection = () => {
  const factList = [
    {
      icon: { src: Hosting },
      title: 'Sicheres Hosting',
      description: 'Unsere Infrastruktur unterliegt den höchsten Sicherheitsanforderungen',
      alt: 'icon',
    },
    {
      icon: { src: Backup },
      title: 'Backups',
      description:
        'Ihre Webseite wird permanent gesichert. Sie können sogar vorherige Versionen ganz einfach per Knopfdruck wiederherstellen',
      alt: 'icon',
    },
    {
      icon: { src: Support },
      title: 'Support',
      description:
        'Sie bekommen einen persönlichen Kundenberater zugewiesen, der Sie während der gesamten Laufzeit betreut.',
      alt: 'icon',
    },
  ];
  return (
    <div className={styles.devopsWrapper}>
      <Container>
        <div className={styles.devopsHolder}>
          {factList.map((factItem, key) => (
            <div key={key} className={styles.devopsBox}>
              <img src={factItem.icon.src} alt={factItem.alt} />
              <h3 className={styles.title}>{factItem.title}</h3>
              <p className={styles.description}>{factItem.description}</p>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
};

export default DevopsSection;
