import React, { useState } from 'react';

import '../../styles/main.scss';
import { CookiesShowContext } from '../cookies/cookies-context/cookies-context';
import { CookiesSnackbar } from '../cookies/cookies';

const LayoutLandingPage = ({ children }) => {
  const [showCookies, setShowCookies] = useState(false);
  return (
    <CookiesShowContext.Provider
      value={{
        showCookies,
        setShowCookies,
      }}
    >
      {children}
      <CookiesSnackbar />
    </CookiesShowContext.Provider>
  );
};

export default LayoutLandingPage;
