import React from 'react';
import clsx from 'clsx';

import styles from './testimonial.module.scss';
const Bullet = ({ data, activeItem }) => {
  return (
    <div className={styles.dotWrapper}>
      <ul className={styles.dotList}>
        {data.map((bullet, idx) => {
          return <li key={idx} className={clsx(idx === activeItem && styles.active, styles.listItem)}></li>;
        })}
      </ul>
    </div>
  );
};

export default Bullet;
