import React, { useState } from 'react';
import { animated as a, useSpring, config } from 'react-spring';
import { useFormik } from 'formik';
import Star from '../../images/icons/star.svg';
import { TextField } from '../forms/text-field';
import { useInView } from 'react-intersection-observer';
import { Checkbox } from '../forms/checkbox';
import { SubmitButton } from '../../components/button/submit';
import { AnimatedArrow } from './animated-arrow';
import { AnimatedFireworks } from './animated-fireworks';
import { TrailAnimation } from '../animate/TrailAnimation';
import FORM_TYPES from '../../../api/_form-types-handlers/FORM_TYPES';

import CheckIcon from '../../images/icons/check-icon-primary.svg';
import CloseIcon from '../../images/icons/close-icon-secondary.svg';
import IconSSL from '../../images/icons/project-inquiry/ssl-icon.svg';

import styles from './contact-form.module.scss';
import clsx from 'clsx';
import { useCallback } from 'react';

const validate = (values) => {
  const errors = {};
  const maxNameLength = 80;
  if (!values.name) {
    errors.name = 'Ups, dieses Angabe brauchen wir auf jeden Fall.';
  } else if (values.name.length > maxNameLength) {
    errors.name = `Ups, hängt bei Ihnen eine der Tasten? Sie haben zu langen Text hier angegeben! Bitte maximal ${maxNameLength} Zeichen angeben.`;
  }

  if (!values.email) {
    errors.email = 'Ups, dieses Angabe brauchen wir auf jeden Fall.';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Geben Sie bitte eine gültige E-Mail-Adresse an.';
  }

  if (!values.agreement) {
    errors.agreement = 'Akzeptieren Sie bitte die Datenschutzbedingungen';
  }

  return errors;
};

const defaultValues = {
  name: '',
  email: '',
  phone: '',
  agreement: false,
  formType: FORM_TYPES.CALLBACK_REQUEST,
};

export const ContactForm = ({ formId, checkboxId }) => {
  const [finished, setFinished] = useState(false);
  const [serverError, setServerError] = useState(false);
  const { handleChange, errors, touched, handleBlur, isSubmitting, handleSubmit, resetForm, values } = useFormik({
    enableReinitialize: true,
    initialValues: defaultValues,
    validate: validate,
    onSubmit: async (data, { setErrors }) => {
      setServerError(false);
      const response = await fetch('/api/kontakt', {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        setFinished(true);
        if (window && typeof window.trackGoogleAdsEvent === `function`) {
          window.trackGoogleAdsEvent('conversion', { send_to: 'AW-685688701/064TCPfbm8ABEP2O-8YC' });
        }
      } else if (response.status === 409) {
        const jsonData = await response.json();
        const errors = jsonData.errors;
        if (Array.isArray(errors)) {
          const e = {};
          errors.forEach((errorResult) => {
            e[errorResult.field] = errorResult.msg;
          });
          setErrors(e);
        } else {
          setFinished(true);
          setServerError(true);
        }
      } else {
        setFinished(true);
        setServerError(true);
      }
    },
  });

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 1,
  });

  const reset = useCallback(() => {
    setFinished(false);
    resetForm();
  }, [setFinished, resetForm]);

  const finishedSpring = useSpring({
    opacity: finished ? 1 : 0,
    transform: `scale(${finished ? 1 : 0.8})`,
    pointerEvents: finished ? 'all' : 'none',
    delay: finished ? 100 : 0,
    config: {
      ...config.default,
      tension: finished ? config.default.tension : 300,
      clamp: true,
    },
  });

  const formSpring = useSpring({
    opacity: finished ? 0 : 1,
    transform: `scale(${finished ? 0.8 : 1})`,
    onRest: () => {
      if (finished) {
        setServerError(false);
      }
    },
    delay: !finished ? 100 : 0,
    config: {
      ...config.default,
      tension: !finished ? config.default.tension : 300,
      clamp: true,
    },
  });

  return (
    <div className={styles.wrapper}>
      <a.div className={styles.mainContent} style={formSpring}>
        <h2 className={styles.heading}>Wie können wir sie erreichen?</h2>
        <p className={styles.text}>
          100% kostenlos & unverbindlich. Für evtl. Rückfragen können Sie auch Ihre Telefonnummer hinterlassen.
        </p>
        <form className={styles.form} onSubmit={handleSubmit}>
          <TextField
            id={formId}
            placeholder="Name"
            name="name"
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.name && errors.name}
            value={values.name}
          />
          <TextField
            id={formId}
            placeholder="E-mail"
            name="email"
            required={true}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.email && errors.email}
            value={values.email}
          />
          <TextField
            id={formId}
            type="tel"
            placeholder="Telefonnummer (optional)"
            name="phone"
            required={false}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.phone && errors.phone}
            value={values.phone}
          />

          <Checkbox
            id={checkboxId || 'contact-form-agreement'}
            className={styles.agreement}
            name="agreement"
            onChange={handleChange}
            error={touched.agreement && errors.agreement}
            checked={values.agreement}
          >
            Ich habe die Hinweise zum Datenschutz zur Kenntnis genommen.
          </Checkbox>
          <div className={styles.submitContainer}>
            <div className={styles.animatedContainer}>
              <AnimatedFireworks className={styles.sparkle} />
              <div ref={ref} className={styles.animatedContainer}>
                <SubmitButton
                  className={clsx(styles.submitButton, inView && styles.pulse)}
                  label="Absenden"
                  disabled={isSubmitting}
                  loading={isSubmitting}
                />
              </div>

              <AnimatedFireworks className={styles.sparkle} />
            </div>
          </div>

          <div className={styles.bottomDetails}>
            <div className={styles.ssl}>
              <img src={IconSSL} alt="SSL badge" />
              <p>
                100% Sichere Datenübertragung. <br />
                Keine Weitergabe der Daten an Dritte.
              </p>
            </div>
            <div className={styles.arrowWrapper}>
              <AnimatedArrow className={styles.arrow} />
              <div className={styles.arrowTextWrapper}>
                <TrailAnimation>Sie möchten auch eine erfolgreiche Website?</TrailAnimation>
              </div>
            </div>
          </div>
          <h5 className={styles.subHeading}>
            <span>Hohe Kundenzufriedenheit:</span>
            <span className={styles.stars}>
              {Array.from(new Array(5)).map((_, idx) => (
                <img key={idx} src={Star} alt="Star icon" />
              ))}
            </span>
          </h5>
        </form>
      </a.div>
      <a.div className={styles.serverResponseContainer} style={finishedSpring}>
        <div>
          {serverError ? (
            <img src={CloseIcon} role="presentation" alt="Close icon" />
          ) : (
            <img src={CheckIcon} role="presentation" alt="Check icon" />
          )}
          <h2 className={styles.heading}>
            {serverError ? 'Es ist ein Fehler aufgetreten' : 'Vielen Dank für Ihre Anfrage'}
          </h2>
          <p className={styles.text}>
            {serverError
              ? 'Ihre Anfrage konnte leider nicht verarbeitet werden. Sie können in der Zwischenzeit auch telefonisch erreichen: +49 40 88215940'
              : 'Wir werden uns bei Ihnen innerhalb von 24 Stunden melden und freuen uns jetzt schon mehr über Ihr Projekt zu erfahren!'}
          </p>
        </div>
        <SubmitButton className={clsx(styles.submitButton)} label="Formular einblenden" onClick={reset} />
      </a.div>
    </div>
  );
};
