import React from 'react';
import styles from './testimonial.module.scss';
import Star from '../../../images/icons/star.svg';

const StarHolder = ({ star }) => {
  let images = [];

  for (let i = 0; i < star; i++) {
    images.push(<img key={i} src={Star} alt="star" />);
  }

  return <div className={styles.starHolder}>{images}</div>;
};

export default StarHolder;
