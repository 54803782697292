import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import Container from '../../container/container';
import { ContactForm } from '../../hero-contact-form/contact-form';
import Phone from '../../../images/icons/phone-orange.svg';
import Mail from '../../../images/icons/mail-orange.svg';
import styles from './contact-section.module.scss';

export const ContactFormSection = () => {
  const {
    portrait: {
      childImageSharp: { fixed: portrait },
    },
  } = useStaticQuery(
    graphql`
      query {
        portrait: file(relativePath: { eq: "portrait_es.jpg" }) {
          childImageSharp {
            fixed(width: 150, height: 150, quality: 100) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }
      }
    `
  );

  return (
    <div className={styles.wrapper} id="contact-section">
      <Container className={styles.container}>
        <div className={styles.title}>
          <h2>In 2 Minuten individuelles Angebot anfragen.</h2>
        </div>
        <div className={styles.content}>
          <div className={styles.textWrapper}>
            <h2>In 2 Minuten individuelles Angebot anfragen.</h2>
            <div className={styles.ceoWrapper}>
              <Img
                fixed={portrait}
                width={150}
                height={150}
                alt="Experte für moderne Websites"
                className={styles.portrait}
              />
              <div className={styles.ceoInfo}>
                Mein Name ist Elena Straub. Ich bin Ihr Ansprechpartner für moderne Websites und werde Ihre Anfrage
                persönlich bearbeiten.
              </div>
            </div>
            <div className={styles.contactInfoDetailsWrapper}>
              <div className={styles.contactItem}>
                <a href="tel:+494088215940">
                  <img src={Phone} alt="Phone icon" />
                  <span>+49 40 88215940</span>
                </a>
                <p>Sie erreichen uns Mo. – Fr. von 09:00 Uhr – 18:00 Uhr</p>
              </div>
              <div className={styles.contactItem}>
                <a href="mailto:kontakt@evelan.de">
                  <img src={Mail} alt="Mail icon" />
                  <span>kontakt@evelan.de</span>
                </a>
                <p>Oder schreiben Sie uns einfach eine Nachricht.</p>
              </div>
            </div>
          </div>
          <div className={styles.formWrapper}>
            <ContactForm formId={`form-id-${Math.random(10)}`} checkboxId={`contact-section-id-${Math.random(10)}`} />
          </div>
        </div>
      </Container>
    </div>
  );
};
