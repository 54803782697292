import React, { useState, useRef, useEffect } from 'react';
import { useSpring, animated as a } from 'react-spring';

export const AnimatedArrow = ({ className }) => {
  const arrow1 = useRef();
  const arrow2 = useRef();
  const [show, setShow] = useState(false);
  const [arrow1DashArray, setArrow1DashArray] = useState(0);
  const [arrow2DashArray, setArrow2DashArray] = useState(0);

  const arrow1Spring = useSpring({
    dashOffset: show ? 0 : arrow1DashArray,
  });

  const arrow2Spring = useSpring({
    dashOffset: show ? 0 : arrow2DashArray,
  });

  useEffect(() => {
    setArrow1DashArray(arrow1.current ? arrow1.current.getTotalLength() : 0);
    setArrow2DashArray(arrow2.current ? arrow2.current.getTotalLength() : 0);
  }, [arrow1, arrow2]);

  useEffect(() => {
    setTimeout(() => setShow(true), 500);
  }, []);

  return (
    <>
      <svg
        className={className}
        width="31"
        height="82"
        viewBox="0 0 31 82"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          opacity: show ? 1 : 0,
        }}
      >
        <a.path
          ref={arrow1}
          strokeDasharray={arrow1DashArray}
          strokeDashoffset={arrow1Spring.dashOffset}
          d="M23 81C28 69 39 32.9605 6 2.5L24 3.5"
          stroke="#E56721"
          strokeWidth="4"
        />
        <a.path
          ref={arrow2}
          strokeDasharray={arrow2DashArray}
          strokeDashoffset={arrow2Spring.dashOffset}
          d="M19.5 79.5C24.5 68 34.5 32.5 2.5 4.5V23"
          stroke="#E56721"
          strokeWidth="4"
        />
      </svg>
    </>
  );
};
