import React from 'react';
import clsx from 'clsx';
import styles from './content-headline.module.scss';

export function ContentHeadline({ className, title, subtitle = '', variant = 'highlight' }) {
  return (
    <div className={clsx(styles.wrapper, className)}>
      <h2
        className={clsx(styles.title, variant === 'normal' && styles.normal, variant === 'inverse' && styles.inverse)}
      >
        {title}
      </h2>
      {subtitle && <p className={clsx(styles.subTitle, variant === 'inverse' && styles.inverse)}>{subtitle}</p>}
    </div>
  );
}
