import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import Container from '../../container/container';
import { ContentHeadline } from '../../headings/content-headline';
import CtaButton from '../../button/cta-button';
import IconLightning from '../../../images/icons/website-benefit-icons/icon-lightning.svg';
import IconMegaphone from '../../../images/icons/website-benefit-icons/icon-megaphone.svg';
import IconGoal from '../../../images/icons/website-benefit-icons/icon-goal.svg';
import IconEuro from '../../../images/icons/website-benefit-icons/icon-euro.svg';
import IconEye from '../../../images/icons/website-benefit-icons/icon-eye.svg';
import IconThumbsUp from '../../../images/icons/website-benefit-icons/icon-thumbs-up.svg';
import styles from './website-benefit-section.module.scss';

const WebsiteBenefitSection = () => {
  const {
    portrait: {
      childImageSharp: { fixed: portrait },
    },
  } = useStaticQuery(
    graphql`
      query {
        portrait: file(relativePath: { eq: "portrait_as.jpg" }) {
          childImageSharp {
            fixed(width: 150, height: 150, quality: 100) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }
      }
    `
  );

  const websiteBenefit = [
    {
      asset: IconLightning,
      content: (
        <div>
          <h3>Mit der Zeit gehen zu können!</h3>
          <p>
            In der heutigen Zeit ist eine moderne, ansprechende und ultraschnelle Webseite ein Muss für jedes
            Unternehmen, um präsent auf dem Markt zu sein. Dabei spielt weder die Branche noch die Größe Ihres
            Unternehmens eine Rolle.
          </p>
        </div>
      ),
    },
    {
      asset: IconMegaphone,
      content: (
        <div>
          <h3>Bekanntheitsgrad als wichtiger Faktor!</h3>
          <p>
            Sie können das beste Produkt auf dem Markt haben und sich von der Konkurrenz deutlich abheben, aber es
            bringt Ihnen nichts, wenn man unbekannt bleibt. Dank Ihrer modernen und ultraschnellen Webseite gelingt es
            Ihnen den Bekanntheitsgrad zu steigern und sich von der Konkurrenz abzuheben.
          </p>
        </div>
      ),
    },
    {
      asset: IconGoal,
      content: (
        <div>
          <h3>Klar definiertes Ziel!</h3>
          <p>
            Wichtig ist im Vorwege das Ziel Ihrer Webseite klar zu definieren. So legen Sie einen entscheidenden
            Baustein für den Aufbau Ihrer Webseite von Anfang an fest. Sie entscheiden ob Sie die Neukunden erreichen
            möchten, ob Sie bestehende oder neue Produkte präsentieren möchten, wie Sie die Bestandskunden informieren
            möchten und ob Sie Dienstleistungen verkaufen möchten. Es ist Ihre Entscheidung, was Sie mit Ihrer Webseite
            erreichen möchten.
          </p>
        </div>
      ),
    },
    {
      asset: IconEuro,
      content: (
        <div>
          <h3>Ausschlaggebend für ein optimales Ergebnis!</h3>
          <p>
            Ein wichtiger Baustein für Ihren Erfolg ist eine moderne und ultraschnelle Webseite. Es wird ein
            unvergesslicher Erlebnis für Ihre Kunden, da die Webseite sehr schnell aufgerufen wird und
            selbstverständlich responsive ist. Das heißt die Webseite wird auf jedem Gerät in optimaler Darstellung
            ausgeliefert. Die Ladegeschwindigkeit ist ein wichtiger Rankingfaktor für die bessere Google Platzierung.
            Somit werden Sie schneller gefunden und die Conversion Rate steigt. Auch die Sicherheitsanforderungen gem.
            DSGVO werden erfüllt und Ihre Webseite wird gegen Hackerangriffe geschützt.
          </p>
        </div>
      ),
    },
    {
      asset: IconEye,
      content: (
        <div>
          <h3>Wertvoller Content ist sehr wichtig!</h3>
          <p>
            Individuell erstelltes Design ist ohne Zweifel wichtig nicht nur fürs Auge, sondern auch um sich von der
            Konkurrenz abzuheben. Aber auch der Webcontent spielt eine große Rolle. Mit gutem Inhalt bieten Sie Ihren
            Kunden einen deutlichen Mehrwert.
          </p>
        </div>
      ),
    },
    {
      asset: IconThumbsUp,
      content: (
        <div>
          <h3>Zufriedenheitsgarantie!</h3>
          <p>
            Sie können sich entspannt zurücklehnen und wir kümmern uns um Ihre Webseite. Sie sagen uns, was Sie haben
            möchten und wir setzen es für Sie um. Wir geben Ihnen eine Zufriedenheitsgarantie und werden solange an
            Ihrer Webseite arbeiten bis Sie voll und ganz zufrieden sind. Bei uns erhalten Sie das rundum sorglos Paket
            zum einen attraktiven Festpreis.
          </p>
          <h3>Alles ganz einfach!</h3>
          <p>
            Sie teilen uns mit wie die neue Webseite aussehen soll und was Sie damit erreichen möchten. Wir kümmern uns
            darum, setzen Ihre Wünsche um und erstellen für Sie eine perfekte Webseite.
          </p>
        </div>
      ),
    },
  ];

  return (
    <Container className={styles.container}>
      <ContentHeadline title="Darum Brauchen Sie eine Firmenwebseite!" className={styles.headline} />
      <div className={styles.ceoWrapper}>
        <Img fixed={portrait} width={150} height={150} alt="CEO" className={styles.portrait} />
        <div className={styles.ceoInfo}>
          Mein Name ist Andreas Straub, ich bin der Inhaber und Geschäftsführer der Evelan GmbH.
        </div>
      </div>
      <div className={styles.benefitWrapper}>
        {websiteBenefit.map(({ asset, content }, index) => (
          <div key={index} className={styles.item}>
            <img src={asset} className={styles.icon} alt={`Website Benfit Icon ${index}`} />
            {content}
          </div>
        ))}
      </div>
      <CtaButton className={styles.ctaWrapper} cta={{ to: '/webdesign/#contact-section', label: 'Jetzt Anfragen' }} />
    </Container>
  );
};

export default WebsiteBenefitSection;
