import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Container from '../../container/container';
import styles from './referenzen-section.module.scss';
import { WorksPreview } from './works-preview/works-preview';

export const ReferenzenSection = () => {
  const {
    alexMobile,
    alexDesktop,
    makabauDesktop,
    makabauMobile,
    profiliumDesktop,
    profiliumMobile,
    positivDesktop,
    positivMobile,
    rheinstettenTvDesktop,
    rheinstettenTvMobile,
  } = useStaticQuery(graphql`
    query {
      alexMobile: file(relativePath: { eq: "mobile-alex.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 275, maxHeight: 455, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      alexDesktop: file(relativePath: { eq: "desktop-alex.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 645, maxHeight: 455, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      makabauDesktop: file(relativePath: { eq: "desktop-makabau.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 645, maxHeight: 455, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      makabauMobile: file(relativePath: { eq: "mobile-makabau.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 275, maxHeight: 455, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      profiliumDesktop: file(relativePath: { eq: "desktop-profilium.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 645, maxHeight: 455, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      profiliumMobile: file(relativePath: { eq: "mobile-profilium.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 275, maxHeight: 455, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      positivDesktop: file(relativePath: { eq: "desktop-positiv.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 645, maxHeight: 455, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      positivMobile: file(relativePath: { eq: "mobile-positiv.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 275, maxHeight: 455, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      rheinstettenTvDesktop: file(relativePath: { eq: "desktop-rheinstetten-tv.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 645, maxHeight: 455, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      rheinstettenTvMobile: file(relativePath: { eq: "mobile-rheinstetten-tv.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 275, maxHeight: 455, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  const testimonialList = [
    {
      img: {
        desktop: profiliumDesktop,
        mobile: profiliumMobile,
        alt: 'website',
      },
      name: 'Profilium GmbH',
      website: 'profilium.de',
      services: 'Design / Entwicklung / CMS Bereitstellung',
    },
    {
      img: {
        desktop: positivDesktop,
        mobile: positivMobile,
        alt: 'website',
      },
      name: 'Positiv Fitness GmbH',
      website: 'positiv-fitness.de',
      services: 'Design / Entwicklung / Kurplanverwaltung / Multi-Studio Plattform / CMS Bereitstellung',
    },
    {
      img: {
        desktop: rheinstettenTvDesktop,
        mobile: rheinstettenTvMobile,
        alt: 'website',
      },
      name: 'Stadt Rheinstetten',
      website: 'rheinstetten.tv',
      services: 'Design / Entwicklung / CMS mit Video-Blog',
    },
    {
      img: {
        desktop: alexDesktop,
        mobile: alexMobile,
        alt: 'website',
      },
      name: 'Alex Hauk ',
      website: 'alex-hauk.de',
      services: 'Styleguide / Design / Entwicklung / CMS Bereitstellung',
    },
    {
      img: {
        desktop: makabauDesktop,
        mobile: makabauMobile,
        alt: 'website',
      },
      name: 'Ma Ka Bau',
      website: 'ma-ka-bau.de',
      services: 'Design / Entwicklung / CMS Bereitstellung',
    },
  ];

  return (
    <div className={styles.referenzenWrapper}>
      <Container className={styles.headerContainer}>
        <div className={styles.textWrapper}>
          <h2 className={styles.title}>Referenzen</h2>
          <h4 className={styles.subTitle}>Websites, die schon alles können.</h4>
          <p className={styles.paragraph}>
            Alle Websites sind responsive und damit für alle Auflösungen bestens optimiert
          </p>
        </div>
      </Container>
      <Container className={styles.testimonialListContainer}>
        <WorksPreview testimonial={testimonialList} />
      </Container>
    </div>
  );
};
