import React, { useRef, useState, useEffect } from 'react';
import { useSpring, animated as a } from 'react-spring';
import { useInView } from 'react-intersection-observer';

export const AnimatedFireworks = ({ className }) => {
  const sparkleRef = useRef();
  const [show, setShow] = useState(false);
  const [sparkleDashArray, setSparkleDashArray] = useState(0);
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 1,
  });

  const sparkleSpring = useSpring({
    from: {
      dashOffset: sparkleDashArray,
    },
    to: {
      dashOffset: show ? sparkleDashArray * -1 : sparkleDashArray,
    },
  });

  useEffect(() => {
    setSparkleDashArray(sparkleRef.current ? sparkleRef.current.getTotalLength() : 0);
  }, [sparkleRef, setSparkleDashArray]);

  useEffect(() => {
    setTimeout(() => {
      setShow(inView);
    }, 850);
  }, [show, setShow, inView]);

  return (
    <svg
      ref={ref}
      className={className}
      width="19"
      height="47"
      viewBox="0 0 19 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        opacity: show ? 1 : 0,
      }}
    >
      <a.path
        ref={sparkleRef}
        strokeDasharray={sparkleDashArray}
        strokeDashoffset={sparkleSpring.dashOffset}
        d="M12 23.5H0"
        stroke="#E56721"
        strokeWidth="3"
      />
      <a.path
        ref={sparkleRef}
        strokeDasharray={sparkleDashArray}
        strokeDashoffset={sparkleSpring.dashOffset}
        d="M17.4853 37L9 45.4853"
        stroke="#E56721"
        strokeWidth="3"
      />
      <a.path
        ref={sparkleRef}
        strokeDasharray={sparkleDashArray}
        strokeDashoffset={sparkleSpring.dashOffset}
        d="M17.4853 10.4853L9 2"
        stroke="#E56721"
        strokeWidth="3"
      />
    </svg>
  );
};
