import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';
import clsx from 'clsx';
import Container from '../container/container';
import EvelanLogo from '../../images/logo/evelan-gmbh-white.svg';
import ArrowRightIcon from '../../images/icons/arrow_right_icon.svg';
import styles from './footer.module.scss';

const FooterLandingPage = () => {
  const {
    site: {
      siteMetadata: {
        footerMenuLinks,
        contact: { name },
      },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            slogan
            contact {
              name
              street
              zip
              location
              phone
              meetingLink
            }
            footerMenuLinks {
              name
              link
            }
            socialMediaLinks {
              name
              link
            }
          }
        }
      }
    `
  );

  return (
    <footer className={styles.footer}>
      <Container variant="div" className={clsx(styles.footerWrapper, styles.footerLpWrapper)}>
        <div className={styles.logoWrapper}>
          <img src={EvelanLogo} alt="Footer Logo" className={styles.logo} />
          <p className={styles.contactFonts}>
            <Link to="/" rel="noopener noreferrer" target="_blank">
              Visit Evelan Website
              <img src={ArrowRightIcon} width={14} height={11} alt="Pfeil" role="presentation" />
            </Link>
          </p>
        </div>
        <nav className={styles.footerNav}>
          <ul>
            {footerMenuLinks
              .filter((_, idx) => idx < 2)
              .map((link) => (
                <li key={link.name}>
                  <Link to={link.link} activeClassName={styles.selected}>
                    {link.name}
                  </Link>
                </li>
              ))}
            <li key="copyright">
              <div className={styles.copyright}>
                © {name} | {new Date().getFullYear()}
              </div>
            </li>
          </ul>
        </nav>
      </Container>
      <Container variant="div" className={styles.footerWrapperBelowLp}>
        <div className={styles.copyright}>
          © {name} | {new Date().getFullYear()}
        </div>
      </Container>
    </footer>
  );
};

export default FooterLandingPage;
