import React, { useState } from 'react';
import Img from 'gatsby-image';
import { SwipeableSingle } from '../../../swipeable/swipeable-single/swipeable-single';
import Container from '../../../container/container';
import Left from '../../../../images/icons/arrow-left.svg';
import Right from '../../../../images/icons/arrow-right.svg';
import Bullet from '../../testimonial/bullet';

import styles from './works-preview.module.scss';

export const WorksPreview = ({ testimonial }) => {
  const [activeItem, setActiveItem] = useState(0);

  return (
    <div className={styles.works}>
      <button className={styles.prevBtn} onClick={() => activeItem > 0 && setActiveItem(activeItem - 1)}>
        <img src={Left} alt="left" width="40" height="40" />
      </button>
      <SwipeableSingle activeIndex={activeItem} setActiveIndex={setActiveItem}>
        {testimonial.map((testimonialItem, key) => (
          <Container key={key} className={styles.worksPreviewWrapper}>
            <div className={styles.worksPreviewHolder}>
              <div className={styles.worksPreviewDesktopWrapper}>
                <div className={styles.topBar}>
                  <span />
                  <span />
                  <span />
                </div>
                <div className={styles.imageContainer}>
                  <Img
                    className={styles.image}
                    fluid={testimonialItem.img.desktop.childImageSharp.fluid}
                    alt={testimonialItem.img.alt}
                  />
                </div>
              </div>
              <div className={styles.worksPreviewMobileWrapper}>
                <div className={styles.topBar}>
                  <span />
                  <span />
                  <span />
                </div>
                <div className={styles.imageContainer}>
                  <Img
                    className={styles.image}
                    fluid={testimonialItem.img.mobile.childImageSharp.fluid}
                    alt={testimonialItem.img.alt}
                  />
                </div>
              </div>
            </div>
            <div className={styles.worksPreviewContentWrapper}>
              <h3 className={styles.name}>{testimonialItem.name}</h3>
              <a href={`https://${testimonialItem.website}`} rel="noopener noreferrer" target="_blank">
                <h6 className={styles.website}>{testimonialItem.website}</h6>
              </a>
            </div>
            <div className={styles.servicesContainer}>
              <h4>Was wir umgesetzt haben</h4>
              <h6 className={styles.website}>{testimonialItem.services}</h6>
            </div>
          </Container>
        ))}
      </SwipeableSingle>
      <Bullet data={testimonial} activeItem={activeItem} />
      <button
        className={styles.nextBtn}
        onClick={() => activeItem < testimonial.length - 1 && setActiveItem(activeItem + 1)}
      >
        <img src={Right} alt="right" width="40" height="40" />
      </button>
    </div>
  );
};
