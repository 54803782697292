import React from 'react';
import Container from '../../container/container';

import styles from './faq-section.module.scss';
import { Divider } from '../../divider/divider';

const FaqSection = () => {
  const faqList = [
    {
      title: 'Ist es wirklich zum Festpreis?',
      description:
        'Ja, auf jeden Fall! Sie bekommen vor der Beauftragung ein Angebot mit allen Kosten, die transparent dargestellt sind.',
    },
    {
      title: 'Was bedeutet 100% Zufriedenheitsgarantie?',
      description:
        'Wir entwickeln das Design bis Sie 100% zufrieden sind. Sollten sie vom Design nicht überzeugt sein, erstatten wir Ihnen das Geld zurück.',
    },
    {
      title: 'Was ist Smart CDN?',
      description:
        'Smart CDN ein cloud basierter Hosting-Service, den wir für Ihre Website einsetzten. Damit ergeben sich für Sie gleich mehrere Vorteile. Ihre Website wird blitzschnell geladen. Zudem spielt die Anzahl der Besucher keine große Rolle. Ihre Website ist auch bei sehr hohen Ansturm an Besuchern zuverlässig erreichbar. Darüber hinaus sind Sie auf der sicheren Seite, wenn es um Hackerangriffe geht. Dank eingebautem DDoS-Schutz ist Ihre Webseite auch auf die schlimmsten Szenarien gut vorbereitet.',
    },
    {
      title: 'Wie lange dauert die Fertigstellung meiner Webseite?',
      description:
        'Das hängt von der Art Ihrer Website ab. Eine einfache Standardseite bestehend aus einer Startseite, einem Kontaktformular, Impressum und einer Datenschutzerklärung ist in etwa 10 bis 14 Tagen fertiggestellt. Eine Individuallösung hingegen kann auch mehr Zeit in Anspruch nehmen. Am besten fragen Sie uns einfach unter Angabe Ihrer Vorstellungen und wir nennen Ihnen einen realistischen Zeitraum.\n',
    },
    {
      title: 'Ist die Website auch für die Smartphones und Tablets optimiert?',
      description:
        'Auf jeden Fall! Wir entwickeln nach dem Mobile-First-Ansatz. "Mobile First" bezeichnet ein Konzept im Responsive Webdesign bei dem die Website als erstes für mobile Endgeräte entwickelt wird. Für größere Displays wird die Seite anschließend erweitert und angepasst. Das führt unterm Strich zu weniger und besser strukturiertem Code und somit zu schnelleren Websites, die auf allen Geräten sehr gut dargestellt werden.',
    },
    {
      title: 'Was wird von mir für die Entwicklung benötigt?',
      description:
        'Wir benötigen auf jeden Fall die Texte für die Website. Gibt es bereits konkrete Vorstellungen zur Website, dann teilen Sie uns dies bitte beim Erstgespräch mit.',
    },
    {
      title: 'Kann ich meine Website später exportieren?',
      description:
        'Ja, sie können jederzeit und kostenfrei einen Datenexport der Website anfordern und bekommen alle Ihre Daten inkl. Bilder in einem maschinen lesbaren Format.',
    },
    {
      title: 'Kann ich meine Website nach der Erstellung selber aktualisieren?',
      description:
        'Ja, mit unserem CMS Paket bekommen Sie am Ende des Projektes einen Zugang, mit dem Sie selber die Inhalte auf Ihrer Website pflegen können. Das ganze funktioniert sogar auf Ihrem Smartphone.',
    },
    {
      title: 'Bieten Sie auch die Logo-Erstellung an?',
      description: 'Ja, auch ein Logo mit dem kompletten Styleguide kann auf Wunsch mitbestellt werden.',
    },
  ];

  return (
    <div className={styles.faqWrapper}>
      <Container>
        <h2 className={styles.mainTitle}>Häufig gestellte Fragen</h2>
        <div className={styles.faqHolder}>
          {faqList.map((faq, idx) => (
            <div key={`fact-${idx}`} className={styles.faqBox}>
              <h5 className={styles.itemTitle}>{faq.title}</h5>
              <Divider className={styles.hr} />
              <p className={styles.itemDescription}>{faq.description}</p>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
};

export default FaqSection;
