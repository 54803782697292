import React, { useEffect, useRef } from 'react';
import useMeasure from 'react-use-measure';
import { ResizeObserver } from '@juggle/resize-observer';
import { animated as a, useSpring } from 'react-spring';
import { useDrag } from 'react-use-gesture';
import styles from './swipeable-single.module.scss';

export const SwipeableSingle = ({ activeIndex, setActiveIndex, children, spacing = 50 }) => {
  const [activeItemMeasureRef, { height, width }] = useMeasure({ polyfill: ResizeObserver });
  const threshold = width / 2;
  const delta_t = useRef(0);

  const containerHeightSpring = useSpring({ height });

  const [containerSpring, setContainerSpring] = useSpring(() => ({
    transform: `translateX(${width * activeIndex * -1 + (activeIndex ? spacing : 0)}px)`,
  }));

  useEffect(() => {
    setContainerSpring({
      transform: `translateX(${width * activeIndex * -1 - (activeIndex ? spacing : 0)}px)`,
    });
  }, [activeIndex, setContainerSpring, spacing, width]);

  const bind = useDrag(({ last, movement: [mx], velocity, elapsedTime, direction: [x, y], cancel }) => {
    const currentPosition = width * activeIndex * -1 - (activeIndex ? spacing : 0);
    let moveX = mx + currentPosition;

    if (Math.abs(x) > Math.abs(y)) {
      // Figure out how to completely disable scroll here
    }

    if (Math.abs(x) < Math.abs(y)) {
      cancel();
      return;
    }

    setContainerSpring({
      transform: `translateX(${moveX}px)`,
    });
    delta_t.current = elapsedTime;

    if (last) {
      const currentPosition = width * activeIndex * -1 - (activeIndex ? spacing : 0);
      let didSlide;
      const absoluteMovement = Math.abs(mx);

      if ((velocity > 0.2 || absoluteMovement > threshold) && Math.abs(x) > Math.abs(y)) {
        if (x < 0 || mx < 0) {
          didSlide = nextSlide(1);
        } else if (x > 0 || mx > 0) {
          didSlide = prevSlide(1);
        } else {
        }
      } else {
        setContainerSpring({
          transform: `translateX(${currentPosition}px)`,
        });
        delta_t.current = elapsedTime;
      }

      if (!didSlide) {
        setContainerSpring({
          transform: `translateX(${currentPosition}px)`,
        });
        delta_t.current = elapsedTime;
      }
    }
  });

  const nextSlide = (numberOfSlides = 1) => {
    if (activeIndex < children.length - 1) {
      let target = activeIndex + numberOfSlides;
      if (target > children.length - 1) target = children.length - 1;
      setActiveIndex(target);
      return true;
    }
    return false;
  };

  const prevSlide = (numberOfSlides = 1) => {
    if (activeIndex > 0) {
      let target = activeIndex - numberOfSlides;
      if (target < 0) target = 0;
      setActiveIndex(target);
      return true;
    }
    return false;
  };

  return (
    <a.div className={styles.heightContainer} style={containerHeightSpring} {...bind()}>
      <a.div className={styles.animationContainer} style={{ ...containerSpring }}>
        {children.map((item, index) => {
          return (
            <div
              key={index}
              ref={activeIndex === index ? activeItemMeasureRef : null}
              className={styles.content}
              style={{
                left: width * index + (index ? spacing : 0),
              }}
            >
              {item}
            </div>
          );
        })}
      </a.div>
    </a.div>
  );
};
