import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import styles from './testimonial.module.scss';
import Container from '../../container/container';
import Img from 'gatsby-image';

import StarHolder from './star-holder';
import CtaButton from '../../button/cta-button';
import Left from '../../../images/icons/arrow-left.svg';
import Right from '../../../images/icons/arrow-right.svg';
import Bullet from './bullet';
import { SwipeableSingle } from '../../swipeable/swipeable-single/swipeable-single';

const Testimonial = () => {
  const titleContent = {
    title: 'WAS UNSERE KUNDEN ÜBER UNS SAGEN',
    subTitle: 'Ein kleiner Auszug aus unseren Kunden',
  };

  const {
    portraitAlexHauk: {
      childImageSharp: { fluid: portraitAlexHauk },
    },
    portraitVitaliFuchs: {
      childImageSharp: { fluid: portraitVitaliFuchs },
    },
    portraitMarcelKauz: {
      childImageSharp: { fluid: portraitMarcelKauz },
    },
  } = useStaticQuery(
    graphql`
      query {
        portraitAlexHauk: file(relativePath: { eq: "testimonials/portrait-alex-hauk.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 80, quality: 85, duotone: { highlight: "#ffffff", shadow: "#004f54" }) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
              presentationWidth
              presentationHeight
            }
          }
        }
        portraitVitaliFuchs: file(relativePath: { eq: "testimonials/portrait-vitali-fuchs.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 80, quality: 85, duotone: { highlight: "#ffffff", shadow: "#004f54" }) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
              presentationWidth
              presentationHeight
            }
          }
        }
        portraitMarcelKauz: file(relativePath: { eq: "testimonials/portrait-marcel-kauz.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 80, quality: 85, duotone: { highlight: "#ffffff", shadow: "#004f54" }) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
              presentationWidth
              presentationHeight
            }
          }
        }
      }
    `
  );

  const testimonialInfo = [
    {
      star: 5,
      testimonialDetails:
        'Der Kundenfokus, Effektivität & die Geschwindigkeit sind bei Evelan zentrale Werte, die ich vom ersten Gespräch bis zur Finalisierung meiner Webseite erfahren habe. Ich bin begeistert!',
      img: portraitAlexHauk,
      name: 'Alex Hauk',
      position: 'Unternehmer',
    },
    {
      star: 5,
      testimonialDetails:
        'Die Zusammenarbeit mit Evelan bereitete uns viel Freude. Es lief alles reibungslos ab. ' +
        'Es wurden alle unsere Wünsche berücksichtigt, Optimierungsvorschläge gemacht, Änderungen kompetent und rasch umgesetzt. ',
      img: portraitVitaliFuchs,
      name: 'Vitali Fuchs',
      position: 'CEO',
    },
    {
      star: 5,
      testimonialDetails:
        'Andreas Straub und sein Team haben tolle Arbeit geleistet und mir nicht zu viel versprochen. Die Zusammenarbeit hat mir mega viel Spaß gemacht. ' +
        'Die neue Homepage ging pünktlich online und ich bin mehr als zufrieden. ' +
        'Wenn ihr etwas cooles und innovatives haben möchtet, dann seid ihr bei Evelan GmbH genau richtig. ' +
        'Ich sage dazu nur eins “TOP”!',
      img: portraitMarcelKauz,
      name: 'Marcel Kauz',
      position: 'Unternehmer',
    },
  ];
  const [activeItem, setActiveItem] = useState(0);

  return (
    <div className={styles.testimonial}>
      <Container className={styles.testimonialContainer}>
        <h2 className={styles.title}>{titleContent.title}</h2>
        <h5 className={styles.subtitle}>{titleContent.subTitle}</h5>
        <button className={styles.prevBtn} onClick={() => activeItem > 0 && setActiveItem(activeItem - 1)}>
          <img src={Left} alt="left" width="40" height="40" />
        </button>
        <div className={styles.sliderContainer}>
          <SwipeableSingle activeIndex={activeItem} setActiveIndex={setActiveItem}>
            {testimonialInfo.map((slide, idx) => (
              <div key={idx} className={styles.testimonialHolder}>
                <div key={idx} className={styles.testimonialBox}>
                  <StarHolder star={slide.star} />
                  <h5 className={styles.description}>{slide.testimonialDetails}</h5>
                </div>
                <div className={styles.clientHolder}>
                  <Img fluid={slide.img} alt={slide.name} className={styles.clientImage} />
                  <div className={styles.clientInfo}>
                    <h5 className={styles.name}>{slide.name}</h5>
                    <h6 className={styles.position}>{slide.position}</h6>
                  </div>
                </div>
              </div>
            ))}
          </SwipeableSingle>
          <Bullet data={testimonialInfo} activeItem={activeItem} />
        </div>
        <button
          className={styles.nextBtn}
          onClick={() => activeItem < testimonialInfo.length - 1 && setActiveItem(activeItem + 1)}
        >
          <img src={Right} alt="right" width="40" height="40" />
        </button>
        <CtaButton cta={{ to: '/webdesign/#contact-section', label: 'Jetzt Anfragen' }} className={styles.ctaWrapper} />
      </Container>
    </div>
  );
};

export default Testimonial;
