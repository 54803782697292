import React from 'react';

import Container from '../../container/container';
import styles from './more-fact.module.scss';

import GoogleGreen from '../../../images/icons/google-green.svg';
import SllGreen from '../../../images/icons/ssl-green.svg';
import SyncGreen from '../../../images/icons/sync-green.svg';

const MoreFact = () => {
  const factList = [
    {
      icon: { src: GoogleGreen, width: 80, height: 80 },
      title: 'Google Optimiert',
      description: 'Ihre Webseite wird besser gefunden',
      alt: 'icon',
    },
    {
      icon: { src: SllGreen, width: 80, height: 80 },
      title: 'SSL Verschlüsselt',
      description: 'Die Datenübertragung ist sicher',
      alt: 'icon',
    },
    {
      icon: { src: SyncGreen, width: 80, height: 80 },
      title: 'Ultraschnelle Ladezeit',
      description: 'Mehr Besucher und längere durchschnittliche Besuchsdauer',
      alt: 'icon',
    },
  ];
  return (
    <div className={styles.moreFactWrapper}>
      <Container>
        <div className={styles.moreFactHolder}>
          {factList.map((factItem, key) => (
            <div key={key} className={styles.moreFactBox}>
              <img
                src={factItem.icon.src}
                width={factItem.icon.width}
                height={factItem.icon.height}
                alt={factItem.alt}
              />
              <h3 className={styles.title}>{factItem.title}</h3>
              <p className={styles.description}>{factItem.description}</p>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
};

export default MoreFact;
