import React from 'react';
import SEO from '../components/seo';
import { HeroContactForm } from '../components/hero-contact-form/hero-contact-form';
import { ClientLogosSection } from '../components/sections/client-logos-section/client-logos-section';
import OurServiceSection from '../components/sections/our-service-section/our-service-section';
import MoreFact from '../components/sections/more-fact/more-fact';
import FaqSection from '../components/sections/faq-section/faq-section';
import DevopsSection from '../components/sections/devops-section/devops-section';
import WebsiteBenefitSection from '../components/sections/website-benefit-section/website-benefit-section';
import { ReferenzenSection } from '../components/sections/referenzen-section/referenzen-section';
import Testimonial from '../components/sections/testimonial/testimonial';
import FooterLandingPage from '../components/footer/footer-lp';
import { ContactFormSection } from '../components/sections/contact-section/contact-section';
import { AboutSection } from '../components/sections/about-section/about-section';
import LayoutLandingPage from '../components/layout/layout-lp';

import styles from './webdesign.module.scss';

const WebdesignPage = () => {
  return (
    <LayoutLandingPage>
      <div className={styles.webdesignWrapper}>
        <SEO
          title="Moderne Website"
          description="Moderne und sichere Websites aus Hamburg. Dank der neuartigen JAMstack-Technologie erreichen Sie mehr Kunden."
        />
        <HeroContactForm page="webdesign" />
        <AboutSection />
        <Testimonial />
        <OurServiceSection />
        <ClientLogosSection />
        <ReferenzenSection />
        <ContactFormSection />
        <MoreFact />
        <WebsiteBenefitSection />
        <DevopsSection />
        <FaqSection />
        <ContactFormSection />
        <FooterLandingPage />
      </div>
    </LayoutLandingPage>
  );
};

export default WebdesignPage;
